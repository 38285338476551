<template>
  <div>
    <Homecontainer />
  </div>
</template>

<script>
import Homecontainer from "@/components/homecontainer";
export default {
  components: { Homecontainer }
};
</script>

<style scoped>
.banner {
  overflow: hidden;
}
</style>
