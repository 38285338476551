<template>
  <div>
    <div>
      <van-swipe :autoplay="3000" indicator-color="white" style="width:100%;height:4rem">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img :src="image.url" style="width:100%;height:100%;" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div>
      <div class="index_market_box">
        <div class="index_market">
          <div class="index_4tu" v-for="(item) in navigation" :key="item.id" @click="toSystem(item.id)">
            <img class="index_4tu_img" :src="item.imgurl" />
            <span class="index_4tu_title">{{ item.title }}</span>
          </div>
        </div>
      </div>

    </div>
    <!-- 查重流程 -->
    <section>
      <div class="index_process">
        <div class="text-center">查重流程</div>
        <div class="title_down_line"></div>
        <div class="index_process_list">
          <div class="index_process_item" v-for="data in explain" :key="data.nember">
            <div class="index_process_left">{{ data.number }}</div>
            <span class="size-28 color-666">{{ data.content }}</span>
          </div>
        </div>
      </div>
    </section>
    <!-- 选择论文检测系统 -->
    <div style="margin-top:0.2rem;">
      <div style="background-color: #fff;padding: 5px 0;">
        <div class="text-center">检测系统</div>
        <div class="title_down_line"></div>
      </div>

      <list :ison="ison"></list>
    </div>
    <div>
      <p
        style="font-size: 0.3rem; color: rgb(102, 102, 102); text-align: center; background: rgb(255, 255, 255); padding: 0.42rem 0px;">
        深圳智汇教育有限公司</p>
    </div>
  </div>
</template>

<script>
import list from "@/views/jclist";
export default {
  data() {
    return {
      ison: true,
      images: [
        {
          id: 1,
          url: require("../assets/img/banner1.jpg")
        },
        {
          id: 2,
          url: require("../assets/img/banner2.jpg")
        }
      ],
      navigation: [
        {
          id: 0,
          imgurl: require("../assets/img/servicea.png"),
          title: "系统介绍"
        },
        {
          id: 1,
          imgurl: require("../assets/img/serviceb.png"),
          title: "常见问题"
        },
        {
          id: 2,
          imgurl: require("../assets/img/icon_8.png"),
          title: "开始查重"
        },
        {
          id: 3,
          imgurl: require("../assets/img/serviced.png"),
          title: "下载报告"
        }
      ],
      explain: [
        {
          number: 1,
          content: "选择要使用的论文检测系统"
        },
        {
          number: 2,
          content: "上传待检测的论文"
        },
        {
          number: 3,
          content: "支付相应价格后等待检测完成通知"
        },
        {
          number: 4,
          content: "查看论文检测报告"
        }
      ],
      system: [
        {
          name: "联文检测",
          id: 1,
          url: require("../assets/img/lianwen.png")
        },
        {
          name: "维普检测",
          id: 2,
          url: require("../assets/img/weipu.png")
        },
        {
          name: "万方检测",
          id: 4,
          url: require("../assets/img/wanfang.png")
        },
        {
          name: "知网检测",
          id: 3,
          url: require("../assets/img/cnki.png")
        }
      ],
      id: "",
      banben: "",
      price: "",
      pricetype: "",
      desc: "",
      cid: ""
    };
  },
  components: {
    list,
  },
  methods: {
    toSystem(item) {
      switch (item) {
        case 0:
          this.$router.push("/introduce");
          break;
        case 1:
          this.$router.push("/matter");
          break;
        case 2:
          this.$router.push("/jcsystem");
          break;
        case 3:
          this.$router.push("/report");
          break;
        default:
          break;
      }
    },
    toCheck(item) {
      this.$router.push({
        path: "/jcfenlei",
        query: {
          id: item
        }
      });
    }
  }
};
</script>

<style scoped>
.index_market_box {
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0;
  background-color: #fff;
}

.index_4tu_title {
  font-size: 13px;
  margin-top: 0.3rem;
}

.index_4tu {
  display: flex;
  flex-direction: column;
  width: 25%;
  box-sizing: border-box;
}

.index_4tu_img {
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}

.index_market {
  display: -webkit-flex;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0.3rem;
  text-align: center;
  margin-top: 0.1rem;
}

section {
  margin-top: 0.2rem;
  background-color: #fff;
}

.index_process {
  overflow: hidden;
}

.index_process_list {
  position: relative;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  display: flex;
  flex-direction: column;
  margin-left: 1.1rem;
  margin-top: 0.6rem;
  margin-bottom: 0.9rem;
}

.index_process_list:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0.3rem;
  height: 100%;
  background: #d7e4ed;
  border-left: 1px dashed #36bb81;
  z-index: -1;
}

.index_process_item {
  display: -webkit-flex;
  -webkit-flex-direction: row;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.33rem 0;
}

.index_process_left {
  width: 0.6rem;
  height: 0.6rem;
  background: url("../assets/img/index_oval.png");
  background-size: 100%;
  background-position: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  line-height: 0.6rem;
  text-align: center;
  font-size: 0.3rem;
  color: #fff;
  margin-right: 0.25rem;
}

.text-center {
  text-align: center;
  font-size: .4rem;
  color: #333;
  margin-top: 0.5rem;
  font-weight: 700;
}

.size-28 {
  font-size: 0.28rem;
}

.color-666 {
  color: #666666;
}

/*应用大厅*/
.application_title {
  text-align: center;
  font-size: 0.4rem;
  color: #333333;
}

.application_hall {
  padding: 0.3rem 0.1rem;
  background: #f8f8f8;
}

.application_cont {
  /* display: -webkit-flex;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 0.2rem;
  margin-bottom: 0.3rem; */
}

.application_item {
  /* display: -webkit-flex;
  -webkit-flex-direction: column;
  display: flex;
  flex-direction: column;
  width: 47%;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */

}

.application_item h2 {
  margin: 10px 0;
}

.application_img {
  height: 1.7rem;
  border: solid #ddd 1px;
}

.application_cont a.application_item:nth-child(even) {
  padding: 0.2rem 0.2rem 0 0.25rem;
}

.application_cont a.application_item:nth-child(odd) {
  padding: 0.2rem 0.25rem 0 0.2rem;
}

.title_down_line {
  width: 0.66rem;
  height: 2px;
  background: #333;
  margin: auto;
  margin-top: 0.25rem;
}
</style>
